.App {
  min-height: 100vh;
  height: max-content;
  position: relative;
  background-position: center;
  overflow-y: auto;
  display: flex;
  z-index: 1;
  scrollbar-width: thin;
  font-family: "Montserrat", sans-serif;
}

.authMain {
  min-width: 100%;
  min-height: calc(100vh - 100px);
}

.main {
  min-height: 100vh;
  width: 100vw;
  margin: 0px;
  padding: 0px;
}

@media (max-width: 768px) {
  .main {
    margin: 0 auto;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:last-child {
    display: none;
  }

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }
}

.ant-alert-message {
  color: #5b667a;
}

.ant-spin-dot-item {
  background-color: #2cbbaf !important;
}

/* total width */

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #d9d9d9;
  border-radius: 3px;
}
::-webkit-scrollbar-track:hover {
  background-color: #d9d9d9;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #00565f;
  border-radius: 3px;
}

::-webkit-scrollbar-corner {
  background-color: #d9d9d9;
}

::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-drawer-left
  > .ant-drawer-content-wrapper {
  border-radius: 0px 30px 30px 0;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-drawer .ant-drawer-content {
  border-radius: 0px 30px 30px 0;
}

.createDateRangePicker .ant-picker-cell-inner {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-width: 30px !important;
  height: 30px !important;
  font-family: "Montserrat", sans-serif;
}

.createDateRangePicker .ant-picker-cell.ant-picker-cell-in-view {
  min-width: 30px !important;
  height: 30px !important;
}

.createDateRangePicker
  .ant-picker-cell.ant-picker-cell-in-view
  .ant-picker-cell-inner {
  min-width: 30px !important;
  height: 30px !important;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner {
  background-color: #00565f !important;
  border-radius: 99px 0px 0px 99px !important;
  height: 30px !important;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: #00565f !important;
  border-radius: 0px 99px 99px 0px !important;
  height: 30px !important;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: #cfe9ec !important;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before,
.createDateRangePicker
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #cfe9ec !important;
  height: 30px !important;
  text-align: center;
}

.createDateRangePicker .ant-btn-primary {
  background-color: #cfe9ec !important;
  border-color: #cfe9ec !important;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-drawer .ant-drawer-content {
  border-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-drawer {
  width: 203px !important;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-drawer-left
  > .ant-drawer-content-wrapper {
  width: 100% !important;
  border-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-popover .ant-popover-inner {
  background: #f8f8f8 !important;
  box-shadow: none;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-popover-placement-bottomRight
  .ant-popover-arrow {
  display: none;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-popover-placement-bottomLeft,
:where(.css-dev-only-do-not-override-sk7ap8).ant-popover-placement-bottom,
:where(.css-dev-only-do-not-override-sk7ap8).ant-popover-placement-bottomRight {
  padding-top: 0px !important;
}

/* Select Dropdown  */
:where(.css-dev-only-do-not-override-byeoj0).ant-select-dropdown
  .ant-select-item-option-content {
  font-family: "Montserrat", sans-serif;
}

:where(.css-dev-only-do-not-override-byeoj0).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 500;
  background-color: #00000029;
  font-family: "Montserrat", sans-serif;
}

:where(.css-byeoj0).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 500;
  background-color: #00000029;
  font-family: "Montserrat", sans-serif;
}
